import React from "react"
import { Link } from "gatsby"
import { Parallax } from 'react-scroll-parallax'
import '../styles/puff.scss'

const Puff = (props) => {
  let elementClass = "puff bg--pink"
  let subtitle = props.subtitle ||"Föreläsning"
  let title = props.title || "Ingenting är omöjligt"
  let text = props.text || "Inspirationsföreläsning om motivation, kost, träning och hälsa."
  let link = props.link || "/forelasningar"

  console.log('puff: ', props)
  if(props.page === 'forelasning' || props.page === 'forelasningar'){
    elementClass = "puff bg--blue"
    subtitle = "Tips"
    title = "Föreläsning + individuell coachning"
    text = "Föreläsningen ingenting är omöjligt passar både små och stora grupper och kan kombineras med individuell coachning för dig och dina medarbetare."
    link = "/forelasning-coachning"
  }

  if(props.block){
    return(
      <div className="puff-block" >
        <div className="container">

            <h3 className="title--normal">Läs mer om</h3>
            <aside className={elementClass}>
              <Link to={link}>
                <h3>{subtitle}</h3>
                <h2 className="title--medium">{title}</h2>
                <p>{text}</p>
              </Link>
            </aside>

        </div>
      </div>

    )
  }
  else if (props.list) {
    return (
      <aside className={elementClass} style={{ background: props.color }}>
        <Link to={link}>
          <h3>{subtitle}</h3>
          <h2 className="title--medium">{title}</h2>
          <p>{text}</p>
        </Link>
      </aside>
    )
  }

  else {
    return(
      <Parallax className={`puff-wrapper ${props.link ? '' : 'align--left'}`} y={[-10, 15]} >
        <aside className={elementClass} style={{background: props.color}}>
          <Link to={link}>
            <h3>{subtitle}</h3>
            <h2 className="title--medium">{title}</h2>
            <p>{text}</p>
          </Link>
        </aside>
      </Parallax>
    )
  }
}

export default Puff
