
import React from 'react';

import HeadTitle from './HeadTitle.js';
import '../styles/PageHeadline.scss';

const PageHeadline = (props) => {
  let post = props.data;
  const size = props.size || "largest";
  const title = props.title || post.title;
  const subtitle = props.subtitle || (post.acf ? post.acf.underrubrik : false);

  return (
    <div id="showMenu" className="page-header">
      <div className="container">
        <div className="page-header__content">
          <HeadTitle title={title} subtitle={subtitle} size={size} />
          {(post.acf && post.acf.ingress) &&
            <p className="preamble" dangerouslySetInnerHTML={{__html: post.acf.ingress}} />
          }
        </div>
      </div>
    </div>
  )
}


export default PageHeadline;
