
import React from 'react';
import LottieLogo from '../logo/lottielogo'
import { Parallax } from 'react-scroll-parallax'
import '../styles/ImageWithLogo.scss'

const ImageWithLogo = (props) => {
  let img = props.img || false;
  let alignLogo = props.alignLogo ? "logo--" + props.alignLogo :false;
  return (
    <aside className="img-with-logo">
      {img}
      <Parallax className={"logo " + alignLogo} y={[10,-10]}>
        <LottieLogo type="short" />
      </Parallax>
    </aside>
  )
}

export default ImageWithLogo;
