import React from 'react'
import '../styles/form.scss'

const PageForm = (props) => {

  return(
    <form method="POST" name={"Form: " + props.page} data-netlify="true">
      <input type="hidden" name="form-name" value={"Form: " + props.page} />
      <div className="input-group">
        <label htmlFor="user-name">Namn</label>
        <input type="text" name="user-name" id="user-name" />
      </div>
      <div className="input-group">
        <label htmlFor="user-email">E-post</label>
        <input type="text" name="user-email" id="user-email" required />
      </div>
      <div className="input-group">
        <label htmlFor="user-message">Meddelande</label>
        <textarea name="user-message" id="user-message" required></textarea>
      </div>
      <div className="input-group submit">
        <button className="submit" type="submit" >Skicka</button>
      </div>
      <input type="hidden" name="from-page" value={props.page} />
    </form>
  )
}

export default PageForm
