
import React from 'react';
// import PropTypes from 'prop-types';
import PageForm from './form.js';
import '../styles/ContactBlock.scss';

import {ListContactInfo} from '../Functions/ListContactInfo.js'

const ContactBlock = (props) => {
  let post = props.post;
  const title = post.title.toLowerCase();
  let contacts = ListContactInfo();

  let email = "";
  Object.keys(contacts).map((key)=>{
    contacts.contacts.map((item)=>{
      if(item.name.match('@')){
        email = item.name;
      }
    })
  })


  return (
    <footer className="bg--gray">
      <div className="container">
        <div className="contact-block flex--row--screen flex--ed">
          <section className="contact-block__info">
            <h3 className="title--normal">Boka {title}</h3>
            <p>Vill du veta mer eller boka {title}? Kontakta mig antingen med formuläret eller via mejl.</p>
            <p><a className="link-arrow" href={"mailto:" + email}>{email}</a></p>
          </section>
          <section className="contact-block__form form">
            <PageForm page={post.slug} />
          </section>
        </div>
      </div>
    </footer>
  )
}

// ContactBlock.propTypes = {
//   post: PropTypes.array.isRequired
// };
export default ContactBlock;
