
import React from 'react';
import '../styles/HeadTitle.scss';


const HeadTitle = (props) => {
  const size = props.size || "largest";
  const title = props.title || false;
  const subtitle = props.subtitle || false;
  const heading = props.heading || false;

  const HeadFirst = heading ? `h${heading}` : "h1";
  const HeadSecond = heading ? `h${heading}` : "h2";

  return (

      <div className="head">
        <HeadFirst className={"title--" + size} dangerouslySetInnerHTML={ { __html: title } } />
        {
          subtitle &&
          <HeadSecond className="head__sub" dangerouslySetInnerHTML={ { __html: subtitle } } />
        }
      </div>

  )
}

export default HeadTitle;
