
import { useStaticQuery, graphql } from "gatsby"

export const AttachedImages = () => {
  const images = useStaticQuery(
    graphql`
     query {
       individuell: file(relativePath: { eq: "c2-small.jpg" }) {
         ...contentImage
       }
       forelasning: file(relativePath: { eq: "fc-small-2.jpg" }) {
         ...contentImage
       }
       forelasningCoachning: file(relativePath: { eq: "ingenting-s.jpg" }) {
         ...contentImage
       }
     }
    `
  )
  return images
}
